// BookingContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
const BookingContext = createContext();

export const useBooking = () => useContext(BookingContext);

export const BookingProvider = ({ children }) => {
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isBookRouteModalOpen, setIsBookRouteModalOpen] = useState(false);
  const [isRouteInfoModalOpen, setIsRouteInfoModalOpen] = useState(false);
  const [isBookPackageModalOpen, setIsBookPackageModalOpen] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const openRouteForm = () => setIsBookRouteModalOpen(true);
  const openPackageForm = () => setIsBookPackageModalOpen(true);
  const closeForm = () => {
    setIsBookRouteModalOpen(false);
    setIsBookPackageModalOpen(false);

    setFormData({
      package: 1,
      route: "",
      name: "",
      email: "",
      phone: "",
      extraServices: [],
      start_date: "",
      extra_nights: 0,
      num_people: 1,
    });
  };

  const closeRouteInfoModal = () => setIsRouteInfoModalOpen(false);

  const [formData, setFormData] = useState({
    package: 1,
    route: "",
    name: "",
    email: "",
    phone: "",
    extraServices: [],
    start_date: "",
    extra_nights: 0,
    num_people: 1,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    console.log(formData.extraServices, "EXTR");
  };

  return (
    <BookingContext.Provider
      value={{
        selectedPackage,
        setSelectedPackage,
        formData,
        setFormData,
        handleInputChange,
        selectedRoute,
        setSelectedRoute,
        isFormValid,
        setIsFormValid,
        isBookRouteModalOpen,
        isBookPackageModalOpen,
        isRouteInfoModalOpen,
        closeRouteInfoModal,
        setIsRouteInfoModalOpen,
        openRouteForm,
        openPackageForm,
        closeForm,
        sessionId,
        setSessionId,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
