import React from "react";
import "./BookForm.css";
import { useTranslation } from "react-i18next";
import BookFormRight from "./right-content/BookFormRight";
import BookFormLeft from "./left-content/BookFormLeft";
import { useBooking } from "../../providers/BookProvider";
import { Link } from "react-router-dom";

const BookForm = ({ type }) => {
  const { selectedRoute, closeForm, selectedPackage } = useBooking();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const routeName = currentLang === "en" ? selectedRoute?.name_en : selectedRoute?.name;
  const packageName = currentLang === "en" ? selectedPackage?.name_en : selectedPackage?.name;
  const titleText = type === "route" ? routeName : packageName;
  return (
    <div className="book-modal-overlay">
      <div className="book-modal-content" onClick={(e) => e.stopPropagation()}>
        <Link to="/">
          <button className="book-close-icon" onClick={closeForm} aria-label="Cerrar">
            &times;
          </button>{" "}
        </Link>
        <h2 className="book-modal-title">{`${t("book.form.header")} - ${titleText}`}</h2>
        <div className="book-form-container">
          <BookFormLeft type={type} />
          <BookFormRight type={type} />
        </div>
      </div>
    </div>
  );
};

export default BookForm;
