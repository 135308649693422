import React from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import BookFormRightFieldLong from "./BookFormRightFieldLong";

const BookFormRightField = ({ label, value }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const { t } = useTranslation();

  return (
    <>
      {!isMobile && (
        <div className="book-form-right-field">
          <strong className="book-form-right-label">{`${t(`${label}`)} :`}</strong>{" "}
          <p className="book-form-right-placeholder">{value || t("book.form.empty")}</p>
        </div>
      )}
      {isMobile && <BookFormRightFieldLong label={label} value={value} />}
    </>
  );
};

export default BookFormRightField;
