import React from "react";
import "../BookForm.css";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";

const PriceField = ({ name, value, isPackage }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="book-form-resume-check">
        <div className="book-form-services-check-container">
          <div className="resume-service-name">
            <span>
              <FaCheck className="book-resume-services-icon" />
            </span>{" "}
            {name || t("book.form.empty")}
          </div>
          {!isPackage && <div className="resume-service-price">{`+ ${Math.round(value) || 0} €`}</div>}
          {isPackage && <div className="resume-service-price">{` ${Math.round(value) || 0} €`}</div>}
        </div>
      </div>
    </div>
  );
};

export default PriceField;
