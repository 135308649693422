import React from "react";
import "./route-info.css";
import { useBooking } from "../../providers/BookProvider";
import RouteInfoSummary from "./RouteInfoSummary";
import RouteInfoStage from "./RouteInfoStage";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const RouteInfo = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { selectedRoute, closeRouteInfoModal, openRouteForm } = useBooking();

  const routeName = currentLang === "en" ? selectedRoute.name_en : selectedRoute.name;
  return (
    <div className="route-info-modal-overlay">
      <div className="route-info-modal-container" onClick={(e) => e.stopPropagation()}>
        <Link to="/">
          <button className="route-info-close-icon" onClick={closeRouteInfoModal} aria-label="Cerrar">
            &times;
          </button>
        </Link>
        <div className="route-info-header">
          <div className="route-info-modal-title-container">
            <h1 className="route-info-modal-title">INFO - {routeName}</h1>
            {!isMobile && (
              <button
                className="route-info-button-book"
                onClick={() => {
                  closeRouteInfoModal();
                  openRouteForm();
                }}
              >
                {t("route.modal.button.book")}
              </button>
            )}
          </div>
          <RouteInfoSummary />
        </div>
        <div className="route-info-stages">
          {selectedRoute.stages.map((stage, index) => (
            <RouteInfoStage stage={stage} index={index} />
          ))}
        </div>
        {isMobile && (
          <button
            className="route-info-button-book"
            onClick={() => {
              closeRouteInfoModal();
              openRouteForm();
            }}
          >
            {t("route.modal.button.book")}
          </button>
        )}
      </div>
    </div>
  );
};

export default RouteInfo;
