import React from "react";
import "./route-info.css";
import { useBooking } from "../../providers/BookProvider";
import { GiDuration } from "react-icons/gi";
import { MdNordicWalking } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { useTranslation } from "react-i18next";

const RouteInfoSummary = () => {
  const { t } = useTranslation();
  const { selectedRoute } = useBooking();

  return (
    <div className="route-info-summary">
      <div className="summary-duration">
        <GiDuration style={{ color: "var(--color-primario)", marginRight: "15px" }} />
        <div>
          {selectedRoute.stages.length - 1} {t("route.modal.info.day.plural")} / {selectedRoute.stages.length}{" "}
          {t("book.form.dutation.nights")}
        </div>
      </div>
      <div className="summary-duration">
        <MdNordicWalking style={{ color: "var(--color-primario)", marginRight: "15px" }} />
        <div>{selectedRoute.kms} KMs</div>
      </div>
      <div className="summary-price">
        <GrMoney style={{ color: "var(--color-fondo)", marginRight: "15px" }} />
        <div>
          {t("route.modal.info.from.price")} {Math.round(selectedRoute.price_basic)} € {t("route.modal.info.person.price")}
        </div>
      </div>
    </div>
  );
};

export default RouteInfoSummary;
