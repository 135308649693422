import React, { useEffect } from "react";
import "./routes.css";
import BookForm from "../book-form/BookForm";
import { useBooking } from "../../providers/BookProvider";
import RouteInfo from "../routes-specs/RouteInfo";
import { useTranslation } from "react-i18next";
import default_img from "../../public/paquete.jpg";
import { Link } from "react-router-dom";

const Route = ({ route }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const { setFormData, setSelectedRoute, openRouteForm, isBookRouteModalOpen, isRouteInfoModalOpen, setIsRouteInfoModalOpen, closeForm } =
    useBooking();

  const logo = route.url_photos;
  const description = currentLang === "en" ? route.description_en : route.description;
  const routeName = currentLang === "en" ? route.name_en : route.name;

  useEffect(() => {
    const handlePopState = () => {
      setIsRouteInfoModalOpen(false);
      closeForm();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState); // Limpieza importante
    };
  }, []);
  return (
    <div className="route-content">
      <img src={logo || default_img} alt="Logo GaliRoutes" className="route-image" />
      <div className="route-title">{routeName}</div>
      <div className="route-description">{description}</div>
      <Link to="/more-info-rutes">
        <button
          className="route-button-more"
          onClick={() => {
            setIsRouteInfoModalOpen(true);
            setSelectedRoute(route);
            setFormData((prev) => ({ ...prev, route: route.id }));
          }}
        >
          {t("route.modal.button.more")}
        </button>
      </Link>
      <Link to="/book-form">
        <button
          className="route-button-book"
          onClick={() => {
            openRouteForm();
            setSelectedRoute(route);
            setFormData((prev) => ({ ...prev, route: route.id }));
          }}
        >
          {t("route.modal.button.book")}
        </button>
      </Link>
      {isBookRouteModalOpen && <BookForm type={"route"} />}
      {isRouteInfoModalOpen && <RouteInfo />}
    </div>
  );
};

export default Route;
