import React from "react";
import "./home.css";
import logo from "../public/logo.png";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div className="background-container">
      <div className="overlay">
        <div className="home-title-container">
          <h1 className="home-title">{t("home.title.p1")} CAMINO </h1>
          <h1 className="home-title second">Peregrino</h1>
          <h1 className="home-title"> ?</h1>
        </div>
        <p className="home-description">{t("home.description.p1")}</p>
        <p className="home-description">{t("home.description.p2")}</p>
        <p className="home-description">{t("home.description.p3")}</p>
        {/* <img src={logo} alt="Logo GaliRoutes" className="home-logo" /> */}
      </div>
    </div>
  );
};

export default Home;
